import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_CUSTOMER_FLAT_FEE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemCustomerFlatFee = props => {
  const { lineItems, isCustomer, marketplaceName, intl } = props;

  const customerCommissionLineItem = lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_FLAT_FEE && !item.reversal
  );

  let commissionItem = null;

  if (isCustomer && customerCommissionLineItem) {
    const commission = customerCommissionLineItem.lineTotal;
    const formattedCommission = commission ? formatMoney(intl, commission) : null;

    commissionItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.customerFlatFee" values={{ marketplaceName }} />
        </span>
        <span className={css.itemValue}>{formattedCommission}</span>
      </div>
    );
  }

  return commissionItem;
};

LineItemCustomerFlatFee.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isCustomer: bool.isRequired,
  marketplaceName: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerFlatFee;
