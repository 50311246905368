import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { denormalisedResponseEntities } from '../util/data';
import { createImageVariantConfig } from '../util/sdkLoader';
import { listingImage } from '../config/configLayout';
import { addMarketplaceEntities } from '../ducks/marketplaceData.duck';

const LANDING_PAGE_SECTION_PER_PAGE = 4;

const { aspectRatio: aspectRatioRaw, variantPrefix = 'listing-card' } = listingImage;
const aspectRatio = 1 / 1; // eslint-disable-line no-eval
const defaultQueryParams = {
  page: 1,
  perPage: LANDING_PAGE_SECTION_PER_PAGE,
  minStock: 1,
  include: ['author', 'images'],
  'fields.listing': [
    'title',
    'geolocation',
    'price',
    'publicData.listingType',
    'publicData.transactionProcessAlias',
    'publicData.unitType',
    // These help rendering of 'purchase' listings,
    // when transitioning from search page to listing page
    'publicData.pickupEnabled',
    'publicData.shippingEnabled',
    'publicData.unit',
  ],
  'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
  'fields.image': [
    'variants.scaled-small',
    'variants.scaled-medium',
    `variants.${variantPrefix}`,
    `variants.${variantPrefix}-2x`,
  ],
  ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
  ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  'limit.images': 1,
};

const orderListingsByIds = (notOrderedListings, params) => {
  let listings = [];
  if (params.ids) {
    params.ids.forEach(id => {
      const listing = notOrderedListings.find(item => item.id.uuid === id);
      if (listing) {
        listings.push(listing);
      }
    });
  } else {
    listings = notOrderedListings;
  }

  return listings;
};

export const ListingsApi = createApi({
  reducerPath: 'ListingsApi',
  tagTypes: ['Listings'],
  baseQuery: fakeBaseQuery(),
  endpoints: builder => ({
    listListings: builder.query({
      queryFn: async (arg, queryApi, _extraOptions, _baseQuery) => {
        const { dispatch, extra: sdk } = queryApi; // eslint-disable-line no-unused-vars
        const params = {
          ...defaultQueryParams,
          ...arg,
        };

        const listingsResponse = await sdk.listings.query(params);

        addMarketplaceEntities(listingsResponse, {});
        const notOrderedListings = denormalisedResponseEntities(listingsResponse);
        const listings = orderListingsByIds(notOrderedListings, params);
        const pagination = listingsResponse.data.meta;

        const data = {
          listings,
          pagination,
        };
        return { data };
      },
      providesTags: (result, error, arg) =>
        result
          ? [...result.listings.map(({ id }) => ({ type: 'Listings', id: id.uuid })), 'Listings']
          : ['Listings'],
    }),
  }),
});

export const { useListListingsQuery, reducer } = ListingsApi;
