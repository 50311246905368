import { UserApi } from '../../ducks/UserApi';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const MAX_RECENTLY_VISITED_LISTINGS = 4;
export const storeListingBrowsingHistory = listingId => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const id = listingId.uuid;

  if (!currentUser?.id?.uuid) {
    return Promise.resolve(true);
  }

  const ref = { id: listingId, type: 'listing' };
  const listings = getMarketplaceEntities(getState(), [ref]);
  const listing = listings.length === 1 ? listings[0] : null;

  if (listing.author.id.uuid === currentUser.id.uuid) {
    return Promise.resolve(true);
  }

  const recentlyVisitedListings =
    currentUser?.attributes?.profile?.protectedData?.recentlyVisitedListings || [];
  let recentlyVisited;

  if (recentlyVisitedListings.includes(id)) {
    return Promise.resolve(true);
  }

  if (recentlyVisitedListings.length >= MAX_RECENTLY_VISITED_LISTINGS) {
    recentlyVisited = recentlyVisitedListings.slice(0, MAX_RECENTLY_VISITED_LISTINGS - 1);
  } else {
    recentlyVisited = recentlyVisitedListings;
  }

  recentlyVisited.unshift(id);

  const updateValues = {
    protectedData: {
      recentlyVisitedListings: recentlyVisited,
    },
  };

  return dispatch(UserApi.endpoints.updateProfile.initiate(updateValues));
};
