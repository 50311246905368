import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import { isCompany } from '../../util/data';

import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, currentPage, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);

  const tabs = [
    {
      text: <FormattedMessage id="UserNav.favoriteListings" />,
      selected: currentPage === 'FavoriteListingsPage',
      linkProps: {
        name: 'FavoriteListingsPage',
      },
    },
  ];

  if (isCompany(currentUser)) {
    tabs.push({
      text: <FormattedMessage id="UserNav.yourListings" />,
      selected: currentPage === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    });
  }

  if (!!currentUser?.id?.uuid) {
    tabs.push({
      text: <FormattedMessage id="UserNav.yourProfile" />,
      selected: currentPage === 'ProfilePage',
      linkProps: {
        name: 'ProfilePage',
        params: {
          id: currentUser?.id?.uuid || '',
        },
      },
    });
  }

  tabs.push({
    text: <FormattedMessage id="UserNav.profileSettings" />,
    selected: currentPage === 'ProfileSettingsPage',
    disabled: false,
    linkProps: {
      name: 'ProfileSettingsPage',
    },
  });
  tabs.push({
    text: <FormattedMessage id="UserNav.accountSettings" />,
    selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
    disabled: false,
    linkProps: {
      name: 'ContactDetailsPage',
    },
  });

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
});

export default compose(connect(mapStateToProps))(UserNav);
